import React, { FC } from 'react';
import Modal from '@material-ui/core/Modal';
import classnames from 'classnames';
import Icon from '../../atoms/BrandIcon';
import { InspireButton } from '../../atoms/button';

import styles from './legalMessageModal.module.css';

interface ILegalMessageModal {
    open: boolean;
    title: string;
    message: string;
    onModalClose?: (e: React.MouseEvent) => void;
}

const LegalMessageModal: FC<ILegalMessageModal> = ({ open, title, message, onModalClose }) => {
    return (
        <Modal open={open} className={styles.modal} onClose={onModalClose}>
            <div className={styles.content}>
                <Icon onClick={onModalClose} icon="action-close" size="m" className={styles.closeIcon} />
                <div className={styles.textBlock}>
                    <p className={classnames(styles.header, 't-header-h3')}>{title}</p>
                    <p className={classnames('t-paragraph', styles.message)}>{message}</p>
                </div>
                <InspireButton className={styles.button} type="primary" text="Back" onClick={onModalClose} />
            </div>
        </Modal>
    );
};

export default LegalMessageModal;
