import React from 'react';
import classNames from 'classnames';

import { ISecondaryBanner } from '../../../@generated/@types/contentful';

import { InspireButton } from '../../atoms/button';
import LegalMessage from '../../atoms/legalMessage';
import RichText from '../../atoms/richText';
import { getGtmIdByName } from '../../../lib/gtm';

import { ISectionComponentProps } from '..';

import styles from './secondaryBanner.module.css';
import ContentfulImage from '../../atoms/ContentfulImage';
import { BUTTON_TYPE, MAIN_TEXT_INLINE, TYPOGRAPHY_CLASS } from './constants';
import ContentfulBackgroundImageContainer from '../../atoms/ContentfulBackgroundImageContainer/contentfulBackgroundImageContainer';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useAuth0 } from '@auth0/auth0-react';

interface ISecondaryBannerProps extends ISectionComponentProps {
    entry: ISecondaryBanner;
    classes?: { [key: string]: { [key: string]: string } };
}

export default function SecondaryBanner(props: ISecondaryBannerProps): JSX.Element {
    const fields = props.entry.fields;
    const classes = props.classes?.sectionBanner;
    const isMobileView = useMediaQuery('max-width: 1024px');
    const { isAuthenticated } = useAuth0();

    if (!fields) return null;

    const {
        hasBorder,
        arrangeInline,
        icon,
        mainText,
        title,
        description,
        linkText,
        linkUrl,
        backgroundImage,
        mobileBackgroundImage,
        legalMessage,
        showOnlyWhenLoggedIn,
        showOnlyWhenLoggedOut,
    } = fields;

    const showDueToLoggedInState =
        (isAuthenticated && showOnlyWhenLoggedIn) ||
        (!isAuthenticated && showOnlyWhenLoggedOut) ||
        (!showOnlyWhenLoggedOut && !showOnlyWhenLoggedIn);

    const gtmId = getGtmIdByName('secondaryBanner', mainText);

    // default to inline alignment if arrangeInline is undefined
    let columnAligned = false;
    if (arrangeInline === false) {
        columnAligned = true;
    }

    const backgroundColor = fields.backgroundColor?.fields.hexColor;
    const mainTextColor = fields.mainTextColor?.fields.hexColor;
    const titleColor = fields.titleColor?.fields.hexColor;
    const descriptionColor = fields.descriptionColor?.fields.hexColor;
    const backgroundImageForMobile = mobileBackgroundImage || backgroundImage;
    const backgroundImageToDisplay = isMobileView ? backgroundImageForMobile : backgroundImage;

    return (
        showDueToLoggedInState && (
            <div
                data-gtm-id={gtmId}
                role="banner"
                className={classNames(
                    styles.secondaryBanner,
                    {
                        [styles.hasBorder]: hasBorder,
                    },
                    classes?.container
                )}
            >
                <ContentfulBackgroundImageContainer
                    asset={backgroundImageToDisplay}
                    backgroundClassName={styles.backgroundImageObjectFit}
                    backgroundColor={backgroundImage ? 'transparent' : backgroundColor}
                >
                    <div
                        className={classNames(
                            styles.wrapper,
                            {
                                [styles.columnAligned]: columnAligned,
                            },
                            classes?.wrapper
                        )}
                    >
                        {icon && (
                            <ContentfulImage
                                gtmId={gtmId}
                                className={classNames(styles.icon, classes?.icon)}
                                asset={icon}
                                imageAlt=""
                            />
                        )}
                        {MAIN_TEXT_INLINE && mainText && (
                            <div
                                data-gtm-id={gtmId}
                                className={classNames(
                                    'mainTextConf',
                                    TYPOGRAPHY_CLASS.MAIN_TEXT,
                                    styles.mainText,
                                    classes?.mainText
                                )}
                            >
                                {mainText}
                            </div>
                        )}
                        <div
                            data-gtm-id={gtmId}
                            aria-label="description block"
                            role="group"
                            className={classNames(styles.descriptionBlock, classes?.descriptionBlock)}
                        >
                            {!MAIN_TEXT_INLINE && mainText && (
                                <div
                                    data-gtm-id={gtmId}
                                    className={classNames(
                                        'mainTextConf',
                                        TYPOGRAPHY_CLASS.MAIN_TEXT,
                                        styles.mainText,
                                        styles.mainTextWithDescription,
                                        classes?.mainText
                                    )}
                                >
                                    {mainText}
                                </div>
                            )}
                            <div
                                data-gtm-id={gtmId}
                                className={classNames(
                                    'titleConf',
                                    TYPOGRAPHY_CLASS.TITLE,
                                    styles.title,
                                    classes?.title
                                )}
                            >
                                {title}
                            </div>
                            <div
                                data-gtm-id={gtmId}
                                className={classNames(
                                    'descriptionConf',
                                    TYPOGRAPHY_CLASS.DESCRIPTION,
                                    styles.description,
                                    classes?.description
                                )}
                            >
                                <RichText text={description} className={styles.descriptionText} />{' '}
                                {legalMessage && (
                                    <LegalMessage
                                        className={classNames(styles.legalMessage, 'descriptionConf')}
                                        legalMessage={legalMessage}
                                    />
                                )}
                            </div>
                        </div>
                        {linkUrl && (
                            <InspireButton
                                gtmId={gtmId}
                                className={classNames(styles.secondaryButton, classes?.secondaryButton)}
                                link={linkUrl}
                                text={linkText}
                                type={BUTTON_TYPE}
                            />
                        )}
                    </div>
                </ContentfulBackgroundImageContainer>
                <style jsx>{`
                    .mainTextConf {
                        color: ${mainTextColor ? `#${mainTextColor}` : 'var(--col--primary1)'};
                    }
                    .titleConf {
                        color: ${titleColor ? `#${titleColor}` : 'var(--col--dark)'};
                    }
                    .descriptionConf {
                        color: ${descriptionColor ? `#${descriptionColor}` : 'var(--col--dark)'};
                    }
                `}</style>
            </div>
        )
    );
}
