import { Next, Options, RenderNode } from '@contentful/rich-text-html-renderer';
import { INLINES, BLOCKS, Block, Text } from '@contentful/rich-text-types';
import { IProductDetailsPagePath } from '../../lib/contentfulDelivery';

import { getLinkDetails } from '../../lib/link';

const renderer = (tag: string, className: string) => (node: Block, next: Next) => {
    const nodeData = next(node.content);
    return nodeData.trim().length > 0 ? `<${tag} class="${className}">${nodeData}</${tag}>` : '';
};

const linkRenderer = (node: Block, next: Next) => {
    // renderer implementation taken from https://github.com/contentful/rich-text/blob/09a15e9635f8b5f2dba0f10648e3ccdcb03d87e5/packages/rich-text-html-renderer/src/index.ts#L37-L40
    // and modified to add class to link tag
    const attributeValue = (value: string) => `"${value.replace(/"/g, '&quot;')}"`;
    const href = typeof node.data.uri === 'string' ? node.data.uri : '';
    const nodeData = next(node.content);
    return nodeData ? `<a href=${attributeValue(href)} class="t-link">${nodeData}</a>` : '';
};

export default function getRichTextOptions(
    productDetailsPagePaths: IProductDetailsPagePath[],
    renderNode?: RenderNode
): Partial<Options> {
    return {
        renderNode: {
            [INLINES.ENTRY_HYPERLINK]: (node: Block): string => {
                if (!(node && node.data && node.data.target)) {
                    return undefined;
                }
                const link = getLinkDetails(node.data.target, { productDetailsPagePaths });
                const content = node.content[0] as Text;
                const linkText = content.value || link.name;
                const href = link.isPhone ? `tel:${link.href}` : link.href;
                if (link.isExternal) {
                    return linkText
                        ? `<a href=${href} target="_blank" rel="noreferrer" class="t-link">${linkText}</a>`
                        : '';
                }

                return linkText ? `<a href=${href} class="t-link">${linkText}</a>` : '';
            },
            [INLINES.HYPERLINK]: linkRenderer,
            [BLOCKS.HEADING_1]: renderer('h1', 't-header-hero'),
            [BLOCKS.HEADING_2]: renderer('h2', 't-header-h1'),
            [BLOCKS.HEADING_3]: renderer('h3', 't-header-h2'),
            [BLOCKS.HEADING_4]: renderer('h4', 't-header-h3'),
            [BLOCKS.HEADING_5]: renderer('h5', 't-header-h3'), // according global.css h5 and h4 have same styles
            [BLOCKS.PARAGRAPH]: renderer('p', 't-paragraph'),
            [BLOCKS.UL_LIST]: renderer('ul', 'list-unordered'),
            [BLOCKS.OL_LIST]: renderer('ol', 'list-ordered'),
            ...(renderNode || {}),
        },
    };
}
