import React, { FC } from 'react';
import { Document } from '@contentful/rich-text-types';
import useRichTextOptions from '../../../common/hooks/useRichTextOptions';
import { documentToHtmlString, RenderNode } from '@contentful/rich-text-html-renderer';

export interface IRichText {
    text: Document;
    className?: string;
    renderNode?: RenderNode;
}

const RichText: FC<IRichText> = ({ text, className, renderNode }) => {
    const richTextOptions = useRichTextOptions(renderNode);

    return (
        <div
            className={className}
            dangerouslySetInnerHTML={{
                __html: documentToHtmlString(text, richTextOptions),
            }}
        />
    );
};

export default RichText;
