import classNames from 'classnames';
import React, { useState } from 'react';
import { ILegalMessage } from '../../../@generated/@types/contentful';
import { CONSTANTS } from '../../../constants/en/commons';
import LegalMessageModal from '../../molecules/legalMessageModal';
import styles from './legalMessage.module.css';

const DEFAULT_TERMS_APPLY_TITLE = 'Terms Apply.';
const DEFAULT_VIEW_DETAILS_TITLE = 'View Details.';

interface IProps {
    className?: string;
    legalMessage: ILegalMessage;
}

const LegalMessage: React.FC<IProps> = ({ className, legalMessage }) => {
    const { termsApplyTitle, viewDetailsTitle, message } = legalMessage.fields;
    const { LEGAL_TERM_CONDITION } = CONSTANTS;

    const [modalOpen, setModalOpen] = useState(false);

    const handleToggleModal = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setModalOpen(!modalOpen);
    };

    return (
        <div className={classNames(className, styles.legalMessage)}>
            {termsApplyTitle || DEFAULT_TERMS_APPLY_TITLE}{' '}
            <span role="button" onClick={handleToggleModal} className={styles.viewDetails}>
                {viewDetailsTitle || DEFAULT_VIEW_DETAILS_TITLE}
            </span>
            <LegalMessageModal
                open={modalOpen}
                title={LEGAL_TERM_CONDITION}
                message={message}
                onModalClose={handleToggleModal}
            />
        </div>
    );
};

export { LegalMessage };
