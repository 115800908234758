import { Options, RenderNode } from '@contentful/rich-text-html-renderer';
import useGlobalProps from '../../redux/hooks/useGlobalProps';
import getRichTextOptions from '../helpers/getRichTextOptions';

const useRichTextOptions = (renderNode?: RenderNode): Partial<Options> => {
    const globalProps = useGlobalProps();

    return getRichTextOptions(globalProps?.productDetailsPagePaths, renderNode);
};

export default useRichTextOptions;
